<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-row class="mb-4">
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="3"
                lg="2"
              >
                <v-btn
                  text
                  color="info"
                  @click="filterDialog = true"
                >
                  <v-icon left>
                    mdi-tune
                  </v-icon>
                  {{ $t('Filter') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <!-- Upload div -->
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            style="height: 200px"
          >
            <input
              ref="fileInput"
              type="file"
              accept=".pdf,.jpg,.jpeg,.png,.xlsx,.xls,.csv,.doc,.docx"
              style="visibility:hidden; width:0; position:absolute; top:-9999px"
              @input="attachmentSelected()"
            >
            <div
              class="attachment-upload rounded"
              @click="chooseAttachment()"
            >
              <div class="attachment-upload-icon">
                <v-icon class="d-block text-center mdil-36px">
                  mdil-cloud-upload
                </v-icon>
                <span class="d-block text--secondary">
                  {{ $t('Upload file') }}
                </span>
              </div>
            </div>
          </v-col>

          <!-- New Attachment -->
          <v-col
            v-if="newAttachment.attachment"
            class="d-flex justify-center"
            cols="12"
            sm="auto"
          >
            <v-icon style="font-size: 64px">
              mdil-image
            </v-icon>
          </v-col>

          <v-col
            v-if="newAttachment.attachment"
            cols="12"
            sm="6"
            md="5"
            lg="4"
            xl="3"
          >
            <div class="col-12 pt-1 pb-3">
              <v-text-field
                id="attach-name"
                v-model="newAttachment.name"
                :label="$t('File Name')"
                dense
                outlined
                hide-details
                autocomplete="off"
              />
            </div>

            <div class="col-12 pt-1 pb-3">
              <v-combobox
                v-model="newAttachment.type"
                :label="`* ${$t('Type')}`"
                :items="attachmentTypesList"
                outlined
                dense
                hide-details
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('Press enter to create to record') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>

            <div class="col-12 py-1">
              <v-btn
                color="primary"
                style="width: 100%"
                @click="store(newAttachment)"
              >
                <v-icon
                  left
                  class="mdil-24px"
                >
                  mdil-check
                </v-icon>
                {{ $t('Save') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Display Attachments -->
        <v-row
          v-for="(files, groupName) in attachments"
          :key="groupName"
          no-gutters
          class="mb-10"
        >
          <v-col cols="12">
            <div class="text-h6 pb-3">
              {{ groupName.split('-')[1] }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col
                v-for="(attachment, index) in files"
                :key="index"
                cols="6"
                sm="3"
                md="2"
                lg="1"
                style="height: 140px"
              >
                <div
                  class="attachment-placeholder"
                  style="height: 100px"
                >
                  <a
                    v-if="$_is_image(attachment.attachment)"
                    class="attachment-container d-block rounded-lg"
                    :href="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                    target="_blank"
                  >
                    <img
                      :src="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                      alt="img"
                    >
                  </a>

                  <a
                    v-else
                    :href="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                    target="_blank"
                    class="attachment-container d-flex grey rounded-lg justify-center"
                    :class="$vuetify.theme.dark ? 'darken-3': 'lighten-4'"
                  >
                    <v-icon
                      v-if="$_is_pdf(attachment.attachment)"
                      style="font-size: 72px"
                    >
                      far fa-file-pdf
                    </v-icon>

                    <v-icon
                      v-if="$_is_doc(attachment.attachment)"
                      style="font-size: 72px"
                    >
                      far fa-file-word
                    </v-icon>

                    <v-icon
                      v-if="$_is_excel(attachment.attachment)"
                      style="font-size: 72px"
                    >
                      far fa-file-excel
                    </v-icon>
                  </a>

                  <div class="ellipses-text text-center pt-2">
                    <div>{{ attachment.name }}</div>
                    <!-- <div class="font-weight-bold text--disabled">
                      {{ attachment.type.name }}
                    </div> -->
                  </div>

                  <v-btn
                    color="info"
                    icon
                    class="edit-attach-btn"
                    :class="[$vuetify.rtl ? 'rounded-tl-0 rounded-bl-0 rounded-tr-0 rounded-br-lg' : 'rounded-tl-0 rounded-bl-0 rounded-tr-0 rounded-br-lg', $vuetify.theme.dark ? 'grey darken-4' : 'white']"
                    @click="editAttachment(attachment)"
                  >
                    <v-icon class="mdil-28px">
                      mdil-pencil
                    </v-icon>
                  </v-btn>

                  <v-btn
                    color="error"
                    icon
                    class="remove-attach-btn"
                    :class="[$vuetify.rtl ? 'rounded-tr rounded-br-0 rounded-tl-0 rounded-bl-lg' : 'rounded-tr rounded-br-0 rounded-tl-0 rounded-bl-lg', $vuetify.theme.dark ? 'grey darken-4' : 'white']"
                    @click="deleteAttachment(attachment.id)"
                  >
                    <v-icon class="mdil-28px">
                      mdil-delete
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!----------------------------->
    <!-- Edit -->
    <!----------------------------->
    <v-navigation-drawer
      v-model="editDrawer"
      width="300"
      absolute
      temporary
    >
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="editData.name"
              :label="$t('File Name')"
              dense
              outlined
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <v-combobox
              v-model="editData.type"
              :label="`* ${$t('Type')}`"
              :items="attachmentTypesList"
              outlined
              dense
              hide-details
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('Press enter to create to record') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="blue"
              outlined
              style="width: 100%"
              @click="update()"
            >
              <v-icon
                left
                class="mdil-24px"
              >
                mdil-check
              </v-icon>
              {{ $t('Save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-navigation-drawer>

    <!----------------------------->
    <!-- Filter -->
    <!----------------------------->
    <v-dialog
      v-model="filterDialog"
      scrollable
      persistent
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Filter Attachments') }}
          <v-spacer />
          <v-btn
            icon
            @click="filterDialog = false"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row>
            <v-col
              cols="12"
              class="mb-3"
            >
              <div class="text-body-2 font-weight-bold text--disabled mb-2">
                {{ $t('Year') }}
              </div>
              <v-checkbox
                v-for="(y, index) in years"
                :key="index"
                v-model="queryParams.years[index]"
                :label="y"
                class="ms-3"
                hide-details
                :ripple="false"
                :true-value="y"
                :false-value="null"
                @change="filterAttachmentTypes()"
              />
            </v-col>

            <v-col cols="12">
              <div class="text-body-2 font-weight-bold text--disabled mb-2">
                {{ $t('Attachment Type') }}
              </div>
              <v-checkbox
                v-for="(type, index) in filtersAttachmentTypesList"
                :key="index"
                v-model="queryParams.types[index]"
                :label="type.type_name"
                class="ms-3"
                hide-details
                :ripple="false"
                :true-value="type.type_id"
                :false-value="null"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            outlined
            small
            class="me-4"
            @click="clearAttachmentFilter()"
          >
            {{ $t('Clear Filter') }}
          </v-btn>

          <v-btn
            color="info"
            depressed
            outlined
            small
            @click="getAttachments()"
          >
            <v-icon
              left
              class="mdil-24px"
            >
              mdil-check
            </v-icon>
            {{ $t('Apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    modelName: { type: String, required: true },
    modelId: { type: Number, required: true },
    modelAttachments: { type: Array, required: true },
  },

  data() {
    return {
      editDrawer: false,
      editData: {},
      attachments: [],
      newAttachment: {},
      filterDialog: false,
      filtersAttachmentTypesList: [],
      queryParams: {
        model_id: this.modelId,
        model_name: this.modelName.toLowerCase(),
        years: [],
        types: []
      },
      years: ['2023', '2024', '2025'],
    }
  },

  computed: {
    ...mapGetters(['attachmentTypesList'])
  },

  watch: {
    modelAttachments: {
      immediate: true,
      deep: true,
      handler() {
        this.attachments = this.modelAttachments
      }
    }
  },

  mounted() {
    this.filterAttachmentTypes()
  },

  methods: {
    ...mapActions([
      'getAttachmentTypesList',
    ]),

    filterAttachmentTypes() {
      let years = this.queryParams.years.filter(x => x != null)

      let data = {
        years: years,
        model_id: this.modelId,
        model_name: this.modelName.toLowerCase(),
      }

      axios.post('attachment/get-attachment-types/', data).then(res => {
        this.filtersAttachmentTypesList = res.data
      })
    },

    editAttachment(attachment) {
      this.editData = { ...attachment, type_id: attachment.type.id }
      this.editDrawer = true
    },

    chooseAttachment() {
      this.$refs.fileInput.click()
    },

    attachmentSelected() {
      // get file size in MB
      let fileSize = this.$refs.fileInput.files[0].size / 1_000_000
      // if file size greater than 5MB show warning
      if (fileSize > 5) {
        return this.$_notify('File size must be less than 5MB', 'error')
      }

      this.newAttachment = {
        attachment: this.$refs.fileInput.files[0],
        name: ''
      }

      setTimeout(() => {
        document.getElementById('attach-name').focus()
      }, 500)

      this.$refs.fileInput.value = ''
    },

    removeAttachment() {
      this.newAttachment = {}
    },

    deleteAttachment(id) {
      this.$_confirm().then(confirmed => {
        if (confirmed) {
          axios.delete(`attachment/${id}/`).then(() => {
            this.$_notify('Deleted successfully')
            this.getAttachments()
          })
        }
      })
    },

    update() {
      this.editData.type = this.editData.type_id
      axios.put(`attachment/${this.editData.id}/`, this.editData).then(() => {
        this.getAttachments()
        this.getAttachmentTypesList()
        this.$_notify('Updated successfully')
        this.editDrawer = false
      })
    },

    store(newAttachment) {
      if (!newAttachment.attachment) {
        this.$_notify('Please upload a file', 'error')
        return
      }

      if (!newAttachment.type) {
        this.$_notify('Please select file type', 'error')
        return
      }

      let fd = new FormData()

      fd.append('model_id', this.modelId)
      fd.append('model_name', this.modelName)
      fd.append('attachment', newAttachment.attachment)
      fd.append('name', newAttachment.name)
      fd.append('type', typeof newAttachment.type == 'string' ? newAttachment.type : JSON.stringify(newAttachment.type))


      axios.post('attachment/', fd).then(() => {
        this.$_notify('Saved successfully')
        this.getAttachments()
        this.getAttachmentTypesList()
        this.newAttachment = {}
      })
    },

    clearAttachmentFilter() {
      this.queryParams.types = []
      this.queryParams.years = []
      this.getAttachments()
      this.filterDialog = false
    },

    getAttachments() {
      axios.post('attachment/get-attachments/', this.queryParams).then(res => {
        this.attachments = res.data
        this.filterDialog = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>