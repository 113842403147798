import axios from 'axios'

const state = {
  attachmentTypesList: [],
}

const getters = {
  attachmentTypesList: state => state.attachmentTypesList,
}

const actions = {
  async getAttachmentTypesList({ commit }) {
    await axios.get('attachment-type/all/').then(response => {
      commit('SET_ATTACHMENT_TYPE_LIST', response.data)
    })
  },
}

const mutations = {
  SET_ATTACHMENT_TYPE_LIST: (state, data) => {
    state.attachmentTypesList = data.map(el => {
      return { 'text': el.name, 'value': el.id }
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
