import axios from 'axios'

const state = {
  companiesList: [],
  companyShareholders: [],
  companiesNotification: {
    count: 0,
    seasonal_form: [],
    social_security: [],
    count_per_company: [],
    lawyer_contract: [],
  },
  dosNumberPlacesList: [
    { text: 'Slemany', value: 1 },
    { text: 'Hawler', value: 2 },
    { text: 'Duhok', value: 3 },
  ],
}

const getters = {
  companiesList: state => state.companiesList,

  companyShareholders: state => state.companyShareholders,

  companiesNotification: state => state.companiesNotification,

  dosNumberPlacesList: state => state.dosNumberPlacesList,
}

const actions = {
  async getCompaniesList({ commit }) {
    await axios.get('company/all/').then(response => {
      commit('SET_COMPANY_LIST', response.data)
    })
  },

  async getCompanyShareholders({ commit }, companyId) {
    await axios.get(`company/${companyId}/shareholders/`).then(res => {
      commit('SET_COMPANY_SHAREHOLDERS', res.data)
    })
  },

  async checkCompaniesNotification({ commit }) {
    axios.get('company/check-notification/').then((res) => {
      commit('SET_NOTIFICATIONS', res.data)
    })
  },
}

const mutations = {
  SET_COMPANY_LIST: (state, data) => {
    state.companiesList = data
  },

  SET_COMPANY_SHAREHOLDERS: (state, data) => {
    state.companyShareholders = data
  },

  SET_NOTIFICATIONS: (state, data) => {
    state.companiesNotification = data
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
